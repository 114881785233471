import React from 'react';
import PerformanceTable from '../components/sections/PerformanceTable';
import ReactGA from 'react-ga';

ReactGA.initialize(process.env.REACT_APP_GA_CODE);

const trackPage = page => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
};

const Performance_table = () => {

  return (
    <>
      <PerformanceTable className="illustration-section-01" />
      {/* <FeaturesTiles />
      <FeaturesSplit invertMobile topDivider imageFill className="illustration-section-02" />
      <Testimonial topDivider /> */}
      {/* <Cta split /> */}
    </>
  );
}

export default Performance_table;
