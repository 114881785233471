import React from 'react';
// import sections
import Hero from '../components/sections/Hero';
import FeaturesTilesRisk from '../components/sections/FeaturesTilesRisk';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import Testimonial from '../components/sections/Testimonial';
import Cta from '../components/sections/Cta';
import Risk_section from '../components/sections/Risk_section';
import ReactGA from 'react-ga';

ReactGA.initialize(process.env.REACT_APP_GA_CODE);

const trackPage = page => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
};

const Risk = () => {

  return (
    <>
      <Risk_section className="illustration-section-01" />
      <FeaturesTilesRisk />
      {/* <FeaturesSplit invertMobile topDivider imageFill className="illustration-section-02" /> */}
      <Testimonial topDivider />
      <Cta split />
    </>
  );
}

export default Risk;
