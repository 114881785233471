import React, { useState } from 'react';

const Modal = ({ src, onClose }) => {
    const backdropStyle = {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000,
    };
  
    const contentStyle = {
      maxWidth: '80%',
      maxHeight: '80vh', // Adjust max height to 80% of the viewport height
      overflow: 'auto',  // Enable scrolling for large images
      padding: '20px',   // Add some padding around the image
      boxSizing: 'border-box', // Include padding in the width and height calculations
      backgroundColor: 'white', // Optional: adds background for transparent images
      borderRadius: '10px',     // Optional: adds rounded corners to the modal
    };
  
    const imgStyle = {
      maxWidth: '100%',
      maxHeight: '100%',
      display: 'block',
      margin: '0 auto', // Center the image
    };
  
    return (
      <div style={backdropStyle} onClick={onClose}>
        <div style={contentStyle} onClick={e => e.stopPropagation()}>
          <img src={src} alt="Enlarged" style={imgStyle} />
        </div>
      </div>
    );
  };
  
  export default Modal;
  