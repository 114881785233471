import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Button from '../elements/Button';

import {isMobile} from 'react-device-detect';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../../App.css';
import { Link } from 'react-router-dom';
const propTypes = {
...SectionProps.types
}
const defaultProps = {
...SectionProps.defaults
}
const Hero = ({
className,
topOuterDivider,
bottomOuterDivider,
topDivider,
bottomDivider,
hasBgColor,
invertColor,
...props
}) => {
const [isItMobile, setIsItMobile] = useState(true)
const [kpi, setKpi] = useState([])
const [chosenDate, setChosenDate] = useState('NONE')
const [chosenFourKubeBalance, setChosenFourKubeBalance] = useState('1000')
const [chosenVOOBalance, setChosenVOOBalance] = useState('1000')
const [stockList, setStockList] = useState([])
const [buyingPriceList, setBuyingPriceList] = useState([])
const [sellingPriceList, setSellingPriceList] = useState([])
const [percentChangeList, setPercentChangeList] = useState([])
const [averagePercChange, setAveragePercChange] = useState('0')
//for the performance table
const [dateList, setDateList] = useState([])
const [fourKubeBal_list, setFourKubeBal_list] = useState([])
const [averagePercentReturn_list, setAveragePercentReturn_list] = useState([])
const [cumulativePercentReturn_list, setCumulativePercentReturn_list] = useState([])
const [voo_list, setVoo_list] = useState([])
const [voo_Challenge_list, setVoo_Challenge_list] = useState([])
const [ticker_list, setTicker_list] = useState([])
var requestOptions = {
method: 'POST',
headers: { 'Content-Type': 'application/json' },
};
useEffect(() => {
if (isMobile === false) {
setIsItMobile(false)
}
requestOptions["body"] = JSON.stringify({})
fetch("https://4ih0epznf8.execute-api.us-east-2.amazonaws.com/".concat('fourkubeStage'))
.then(response => response.json())
.then(data => {
setKpi(data.dataList)
setDateList(data.dataList[0].x)
setFourKubeBal_list(data.dataList[0].yFOURKUBE)
setAveragePercentReturn_list(data.dataList[0].averagePercentReturn_list)
setCumulativePercentReturn_list(data.dataList[0].cumulativePercentReturn_list)
setVoo_list(data.dataList[0].voo_list)
setVoo_Challenge_list(data.dataList[0].vooChallenge_list)
setTicker_list(data.dataList[0].text)
})
}, []);
function displayNotesForGraph(e) {
let theDate = e.points[0].x
setChosenDate(theDate)
let fourKubeBal = e.points[0].data.yFOURKUBE[e.points[0].pointIndex].toFixed(2)
setChosenFourKubeBalance(fourKubeBal)
let VOOBal = e.points[0].data.yVOO[e.points[0].pointIndex].toFixed(2)
setChosenVOOBalance(VOOBal)
//call buy and sell API
var requestOptions = {
method: 'POST',
headers: { 'Content-Type': 'application/json' },
};
fetch("https://morning-brushlands-83469.herokuapp.com/https://bmcwjklg92.execute-api.us-east-2.amazonaws.com/test?theDate=".concat(theDate), requestOptions)
.then(response => response.json())
.then(data => {
let theStockList = JSON.parse(data.body).stockList
setStockList(theStockList)
if (theStockList[0] !== 'NONE') {
setBuyingPriceList(JSON.parse(data.body).buyingPriceList)
setSellingPriceList(JSON.parse(data.body).sellingPriceList)
setPercentChangeList(JSON.parse(data.body).percChangeList)
setAveragePercChange(JSON.parse(data.body).averagePercChange.toFixed(2))
}
})
}
const [videoModalActive, setVideomodalactive] = useState(false);
const openModal = (e) => {
e.preventDefault();
setVideomodalactive(true);
}
const closeModal = (e) => {
e.preventDefault();
setVideomodalactive(false);
}
const outerClasses = classNames(
'hero section center-content',
topOuterDivider && 'has-top-divider',
bottomOuterDivider && 'has-bottom-divider',
hasBgColor && 'has-bg-color',
invertColor && 'invert-color',
className
);
const innerClasses = classNames(
'hero-inner section-inner',
topDivider && 'has-top-divider',
bottomDivider && 'has-bottom-divider'
);
return (
<>
<section
   {...props}
   className={outerClasses}
   >
   <div className="container-sm">
      <div className={innerClasses}>
         <div className="hero-content">
            <h3 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
               Make <span className="text-color-primary">Risk</span> Based Decisions
            </h3>
            Utilizing cutting-edge AI backed by statistical models, the FourKube Risk Metric gives clear
            insight as to when safe times to buy are. Below is the short term risk chart for Apple.
            <br></br><br></br>
            You can see that buying in the purple region when risk is under 4 is a smart time to buy,
            whereas buying when risk is more yellow over 7 may not be the best time to buy.
            <div className="container">
               <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
               <center><br></br><img width="auto" height="auto"
                  src="https://dev-for-fourkube.s3.us-east-2.amazonaws.com/AAPL_market_summary"
                  alt="new"
                  />$AAPL Risk Chart Updated Daily</center>
               </p><br></br>


               <h6>
               <Link to="SubscribersOnly">If you are already a subscriber, click here to view the risk charts</Link>
               </h6>

               <br></br><br></br>
               <div id="risk-sub">
               <h3 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
                  Subscription <span className="text-color-primary">Benefits</span>
               </h3>
               <ol style={{textAlign: 'left'}} className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
               <li>
                  Exclusive access to daily risk metric reports. See any chart at any time via email.
                  <ul>
                     <li>Stocks: S&P500 (VOO), NASDAQ (QQQ), AAPL, TSLA</li>
                     <li>Crypto: BTC, ETH, ADA, DOT</li>
                     <li>More coming soon</li>
                  </ul>
               </li>
               <li>Free access to upcoming assets that are added to the risk reports. The monthly fee will increase for future subscribers.</li>
               <li>Support around the clock. Quick responses to questions or concerns</li>
               <li>Access to our upcoming private Discord. Reach out to us if you want to sign up for early access.</li>
               </ol>
               <br></br>
               <br></br>
               <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                  As we add more features to our risk metric reports, the subscription fee will increase. You can lock in a lower rate now to get continued access to all future updates. Cancel subscription at anytime via Email or Instagram direct message. Past payments are non-refundable.
               </p>
               <br></br>
               <br></br>
               <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                  <span className="text-color-primary">FourKube Risk Metric Access (Monthly)</span><br></br>
                  <b>$19.99/month <strike>$29.99/month</strike></b> (Limited-time sale)<br></br>
                  Cancel anytime. &nbsp;
                  <Link to="Terms-and-Conditions-of-Use">
                  <a
                     className="App-link"
                     target="_blank"
                     rel="noopener noreferrer">
                  Offer terms
                  </a>
                  </Link>
                  &nbsp;apply.
                  <br></br>
                  <Button tag="a" color="primary" size="lg" onClick = {()=> window.open("https://buy.stripe.com/8wM29U8VhfhH50I004", "_blank")}>Buy Monthly Subscription</Button>
               </p>
               <br></br>
            </div>
            </div>
         </div>
      </div>
   </div>
</section>
</>
);
}
Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;
export default Hero;
