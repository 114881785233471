import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import Image from '../elements/Image';
import Modal from '../elements/Modal';
import Plot from 'react-plotly.js';
// import { Table } from 'react-bootstrap';
// import Tabs from 'react-bootstrap/Tabs';
// import Tab from 'react-bootstrap/Tab';
// import 'bootstrap/dist/css/bootstrap.min.css';
import MetaTags from 'react-meta-tags';
import {isMobile} from 'react-device-detect';
import { Table } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { HashLink as Link } from 'react-router-hash-link';
import FeaturesSplit from '../../components/sections/FeaturesSplit';

const propTypes = {
...SectionProps.types
}
const defaultProps = {
...SectionProps.defaults
}
const Hero = ({
className,
topOuterDivider,
bottomOuterDivider,
topDivider,
bottomDivider,
hasBgColor,
invertColor,
...props
}) => {
const [isItMobile, setIsItMobile] = useState(true)
const [kpi, setKpi] = useState([])
const [chosenDate, setChosenDate] = useState('NONE')
const [chosenFourKubeBalance, setChosenFourKubeBalance] = useState('1000')
const [chosenVOOBalance, setChosenVOOBalance] = useState('1000')
const [stockList, setStockList] = useState([])
const [buyingPriceList, setBuyingPriceList] = useState([])
const [sellingPriceList, setSellingPriceList] = useState([])
const [percentChangeList, setPercentChangeList] = useState([])
const [averagePercChange, setAveragePercChange] = useState('0')
//for the performance table
const [dateList, setDateList] = useState([])
const [fourKubeBal_list, setFourKubeBal_list] = useState([])
const [averagePercentReturn_list, setAveragePercentReturn_list] = useState([])
const [cumulativePercentReturn_list, setCumulativePercentReturn_list] = useState([])
const [voo_list, setVoo_list] = useState([])
const [voo_Challenge_list, setVoo_Challenge_list] = useState([])
const [ticker_list, setTicker_list] = useState([])
var requestOptions = {
method: 'POST',
headers: { 'Content-Type': 'application/json' },
};
useEffect(() => {
if (isMobile === false) {
setIsItMobile(false)
}
requestOptions["body"] = JSON.stringify({})
fetch("https://4ih0epznf8.execute-api.us-east-2.amazonaws.com/".concat('fourkubeStage'))
.then(response => response.json())
.then(data => {
setKpi(data.dataList)
setDateList(data.dataList[0].x)
setFourKubeBal_list(data.dataList[0].yFOURKUBE)
setAveragePercentReturn_list(data.dataList[0].averagePercentReturn_list)
setCumulativePercentReturn_list(data.dataList[0].cumulativePercentReturn_list)
setVoo_list(data.dataList[0].voo_list)
setVoo_Challenge_list(data.dataList[0].vooChallenge_list)
setTicker_list(data.dataList[0].text)
})
}, []);
function displayNotesForGraph(e) {
let theDate = e.points[0].x
setChosenDate(theDate)
let fourKubeBal = e.points[0].data.yFOURKUBE[e.points[0].pointIndex].toFixed(2)
setChosenFourKubeBalance(fourKubeBal)
let VOOBal = e.points[0].data.yVOO[e.points[0].pointIndex].toFixed(2)
setChosenVOOBalance(VOOBal)
//call buy and sell API
var requestOptions = {
method: 'POST',
headers: { 'Content-Type': 'application/json' },
};
fetch("https://morning-brushlands-83469.herokuapp.com/https://bmcwjklg92.execute-api.us-east-2.amazonaws.com/test?theDate=".concat(theDate), requestOptions)
.then(response => response.json())
.then(data => {
let theStockList = JSON.parse(data.body).stockList
setStockList(theStockList)
if (theStockList[0] !== 'NONE') {
setBuyingPriceList(JSON.parse(data.body).buyingPriceList)
setSellingPriceList(JSON.parse(data.body).sellingPriceList)
setPercentChangeList(JSON.parse(data.body).percChangeList)
setAveragePercChange(JSON.parse(data.body).averagePercChange.toFixed(2))
}
})
}
const [videoModalActive, setVideomodalactive] = useState(false);
const openModal = (e) => {
e.preventDefault();
setVideomodalactive(true);
}
const closeModal = (e) => {
e.preventDefault();
setVideomodalactive(false);
}
const outerClasses = classNames(
'hero section center-content',
topOuterDivider && 'has-top-divider',
bottomOuterDivider && 'has-bottom-divider',
hasBgColor && 'has-bg-color',
invertColor && 'invert-color',
className
);
const innerClasses = classNames(
'hero-inner section-inner',
topDivider && 'has-top-divider',
bottomDivider && 'has-bottom-divider'
);
return (
<>
<section
   {...props}
   className={outerClasses}
   >
   <div className="container-sm">
      <div className={innerClasses}>
         <div className="hero-content">
            <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
               Four<span className="text-color-primary">Kube</span> Subscriptions
            </h1>
            <div className="container">
               <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                  Utilizing cutting-edge AI backed by statistical models, FourKube’s stock prediction algorithm strives to identify profitable companies to buy. Everyday, our team runs in-house programs to web scrape data which is then aggregated into our database. As this database grows, our algorithm becomes better. Our team of data engineers and data scientists continuously backtest and fine tune our algorithm to optimize its performance. Our algorithm has outperformed the S&P500, NASDAQ, Dow Jones, even top ETFs like $ARKK by over 100%. <br></br><br></br>As a subscriber, you will be emailed every single stock prediction forecasted by this AI algorithm and the results of its on-going performance over time on a consistent schedule. You will also get free updates when the team here makes new improvements to the AI, as well as any new features that we add. Future subscribers will have to an extra fee when these new releases roll out.
                  <br></br><br></br>
               </p>

               <br></br>
               <h3 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
                  FourKube <span className="text-color-primary">Gold</span> Subscription Benefits
               </h3>
               <ol style={{textAlign: 'left'}} className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
               <li>Access to both the FourKube AI Algorithm and the FourKube Risk Metric Indicator</li>
               <li>Free updates to the FourKube AI Algorithm and Risk Metric Indicator. The monthly fee will increase with these updates for future subscribers.</li>
               <li>Support around the clock. Quick responses to questions or concerns via email or Instagram DM.</li>
               <li>Access to our upcoming private Discord. Reach out to us if you want to sign up for early access.</li>
               </ol>
               <br></br>
               <br></br>
               <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                  As our AI algorithm improves over time, the subscription fee will increases as well.
                  However, you can lock in a lower rate now to get access to FourKube Gold algorithm updates for free to avoid paying extra later on.
                  Predictions come in Monday-Thursday via email.
                  Cancel subscription at anytime via email or Instagram DM.
                  Past payments are non-refundable.
               </p>
               <br></br>
               <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
               <b>30 Day Free Trial</b><br></br>
                  <span className="text-color-primary">FourKube GOLD Quarterly</span><br></br>
                  <b>$59.99/quarter <strike>$79.99/quarter</strike></b> (Limited-time sale)<br></br>
                  Cancel anytime. &nbsp;
                  <Link to="Terms-and-Conditions-of-Use">
                  <a
                     className="App-link"
                     target="_blank"
                     rel="noopener noreferrer">
                  Offer terms
                  </a>
                  </Link>
                  &nbsp;apply.
                  <br></br>
                  <Button tag="a" color="primary" size="lg" onClick = {()=> window.open("https://buy.stripe.com/14kcOy2wTfhH50IaEK", "_blank")}>Buy Quarterly Subscription</Button>
               </p>
               <br></br>
               <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                  <span className="text-color-primary">FourKube GOLD Annual</span><br></br>
                  <b>$169.00/year <strike>$240.00/year</strike></b> (Best Deal save $70/year)<br></br>
                  (Also free access to the risk metric) <br></br>
                  Cancel anytime. &nbsp;
                  <Link to="Terms-and-Conditions-of-Use">
                  <a
                     className="App-link"
                     target="_blank"
                     rel="noopener noreferrer">
                  Offer terms
                  </a>
                  </Link>
                  &nbsp;apply.
                  <br></br>
                  <Button tag="a" color="primary" size="lg" onClick = {()=> window.open("https://buy.stripe.com/9AQ5m69Zl0mN50I145", "_blank")}>Buy Annual Subscription</Button>
               </p>



               <br></br>
               <h3 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
                  FourKube <span className="text-color-primary">Gold</span> Subscription Benefits
               </h3>
               <ol style={{textAlign: 'left'}} className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
               <li>All FourKube predictions sent via email from our successfully backtested algorithm</li>
               <li>Optional text alerts, informing you when the new prediction has been emailed so you can buy the stock prediction at the right time</li>
               <li>Free updates to the FourKube AI Algorithm. The subscription fee will increase with these updates for future subscribers, but once bought your price is locked in and you get these updates for free</li>
               <li>Support around the clock. Quick responses to questions or concerns via email or Instagram DM.</li>
               <li>Access to our upcoming private Discord. Reach out to us if you want to sign up for early access.</li>
               </ol>
               <br></br>
               <br></br>
               <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                  As our AI algorithm improves over time, the subscription fee will increases as well.
                  However, you can lock in a lower rate now to get access to FourKube Gold algorithm updates for free to avoid paying extra later on.
                  Predictions come in Monday-Thursday via email.
                  Cancel subscription at anytime via email or Instagram DM.
                  Past payments are non-refundable.
               </p>
               <br></br>
               <br></br>

               <br></br>




               <h3 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
                  FourKube <span className="text-color-primary">Risk</span> Metric
               </h3>
               <p>If you are interested in our other seperate product, the FourKube Risk Metric, check that out here:</p>


               <div className="reveal-from-bottom" data-reveal-delay="50">
                 <ButtonGroup>

                     <Link to="Risk#risk-sub">
                     <Button tag="a" color="primary" >
                       Learn more about FourKube Risk Metric
                       </Button>
                       </Link>
                 </ButtonGroup>
               </div>



            </div>
         </div>
      </div>
   </div>
</section>
</>
);
}
Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;
export default Hero;
