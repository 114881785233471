import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import MetaTags from 'react-meta-tags';
import {isMobile} from 'react-device-detect';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const [isItMobile, setIsItMobile] = useState(true)
  const [kpi, setKpi] = useState([])
  const [chosenDate, setChosenDate] = useState('NONE')
  const [chosenFourKubeBalance, setChosenFourKubeBalance] = useState('1000')
  const [chosenVOOBalance, setChosenVOOBalance] = useState('1000')
  const [stockList, setStockList] = useState([])
  const [buyingPriceList, setBuyingPriceList] = useState([])
  const [sellingPriceList, setSellingPriceList] = useState([])
  const [percentChangeList, setPercentChangeList] = useState([])
  const [averagePercChange, setAveragePercChange] = useState('0')

  //for the performance table
  const [dateList, setDateList] = useState([])
  const [fourKubeBal_list, setFourKubeBal_list] = useState([])
  const [averagePercentReturn_list, setAveragePercentReturn_list] = useState([])
  const [cumulativePercentReturn_list, setCumulativePercentReturn_list] = useState([])
  const [voo_list, setVoo_list] = useState([])
  const [voo_Challenge_list, setVoo_Challenge_list] = useState([])
  const [ticker_list, setTicker_list] = useState([])

  var requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  };
  useEffect(() => {
    if (isMobile === false) {
      setIsItMobile(false)
    }
    requestOptions["body"] = JSON.stringify({})
    fetch("https://4ih0epznf8.execute-api.us-east-2.amazonaws.com/".concat('fourkubeStage'))
    .then(response => response.json())
    .then(data => {
      setKpi(data.dataList)
      setDateList(data.dataList[0].x)
      setFourKubeBal_list(data.dataList[0].yFOURKUBE)
      setAveragePercentReturn_list(data.dataList[0].averagePercentReturn_list)
      setCumulativePercentReturn_list(data.dataList[0].cumulativePercentReturn_list)
      setVoo_list(data.dataList[0].voo_list)
      setVoo_Challenge_list(data.dataList[0].vooChallenge_list)
      setTicker_list(data.dataList[0].text)
    })
  }, []);

  function displayNotesForGraph(e) {
    let theDate = e.points[0].x
    setChosenDate(theDate)
    let fourKubeBal = e.points[0].data.yFOURKUBE[e.points[0].pointIndex].toFixed(2)
    setChosenFourKubeBalance(fourKubeBal)
    let VOOBal = e.points[0].data.yVOO[e.points[0].pointIndex].toFixed(2)
    setChosenVOOBalance(VOOBal)


    //call buy and sell API
    var requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    };
    fetch("https://morning-brushlands-83469.herokuapp.com/https://bmcwjklg92.execute-api.us-east-2.amazonaws.com/test?theDate=".concat(theDate), requestOptions)
    .then(response => response.json())
    .then(data => {
      let theStockList = JSON.parse(data.body).stockList
      setStockList(theStockList)
      if (theStockList[0] !== 'NONE') {
        setBuyingPriceList(JSON.parse(data.body).buyingPriceList)
        setSellingPriceList(JSON.parse(data.body).sellingPriceList)
        setPercentChangeList(JSON.parse(data.body).percChangeList)
        setAveragePercChange(JSON.parse(data.body).averagePercChange.toFixed(2))
      }
    })

}

  const [videoModalActive, setVideomodalactive] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setVideomodalactive(true);
  }

  const closeModal = (e) => {
    e.preventDefault();
    setVideomodalactive(false);
  }

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <>
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
              Frequently <span className="text-color-primary">Asked </span>Questions
            </h1>
            <br></br><br></br>
            <div className="container">
                <ol style={{textAlign: 'left'}} className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                  <li><b><i>"What is FourKube Gold?"</i></b></li>
                    <p>FourKube Gold includes a discounted subscription to:
                    <ul>
                    <li>Access to the FourKube AI Algorithm</li>
                    <li>Access to the Risk Metric Indicator</li>
                    <li>Any future updates</li>
                    </ul>
                     <br></br>Utilizing cutting-edge AI backed by statistical models, FourKube Gold’s stock prediction algorithm strives to identify profitable companies to buy. As a subscriber, you will be emailed on a consistent scheduled time every single prediction forecasted by this algorithm and the results of its on-going performance over time.</p><br></br>
                  <li><b><i>"How much of the prediction should I buy?"</i></b></li>
                    <p>The amount of shares you want to buy is up to you. We send out the predictions of our algorithm, but what you do with this information is your choice. These are high risk, high reward predictions so it is not recommended to put in more than you are willing to lose.</p><br></br>
                  <li><b><i>"How often do predictions come in and what will it look like?"</i></b></li>
                    <p>As a subscriber to FourKube Gold, you will get a prediction email Monday-Thursday and a results email Tuesday-Friday. The prediction emails will have an image like this indicated in green which tickers our algorithm is buying:
                        <center><br></br><img width="300" height="auto"
                      src="https://dev-for-fourkube.s3.us-east-2.amazonaws.com/wordcloud245.jpg"
                      alt="new"
                      /></center>
                      On days when our AI algorithm does not find any stock in which it is confident enough to buy, you will receive an email with an empty bull like this:
                      <center><br></br><img width="300" height="auto"
                      src="https://dev-for-fourkube.s3.us-east-2.amazonaws.com/wordcloud241.jpg"
                      alt="new"
                      /></center>
                    </p><b></b>
                  <li><b><i>"How can I cancel my subscription?"</i></b></li>
                    <p>You may cancel your subscription any time, no questions asked. Just fill out the form <a href="http://fourkube.info/#/Subscribe#unsubscribe">here</a>.</p><br></br>
                  <li><b><i>"How come some months have more predictions than other months?"</i></b></li>
                    <p>
                      FourKube uses companies EPS earnings data to make predictions. EPS earnings come out in huge chunks four times a year. In-between these quarters there is very little data to collect thus making a prediction unlikely. This is why you will notice on our "Performance Graph" and "Performance Table" pages there are consecutive weeks with no action since the algorithm's confidence is not as high during this time.  Rest assured that the algorithm values quality over quantity, once the next quarter starts ramping up, predictions will come.
                    </p><br></br>
                  <li><b><i>"How can I contact/connect with FourKube?"</i></b></li>
                    <p>Don’t hesitate to reach out with any questions or concerns at FourKube4@gmail.com, or DM us on instagram at  @FourKube. You can also join <a className="App-link"
            href="https://www.curiousgnu.com/day-trading"
            target="_blank"
            rel="noopener noreferrer"><u>our Discord here.</u></a></p>
                </ol> <br></br>



                <div className="reveal-from-bottom" data-reveal-delay="600">
                <ButtonGroup>
                  <Link to="Subscribe">
                  <Button tag="a" color="primary" >
                    Subscribe
                    </Button>
                    </Link>&nbsp;&nbsp;&nbsp;
                    <Link to="Performance_graph">
                  <Button tag="a" color="dark" to="Performance_Graph">
                    Performance
                    </Button>
                    </Link>
                </ButtonGroup>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

                  </>
  );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
