import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import { Link } from 'react-router-dom';
import Plot from 'react-plotly.js';
// import { Table } from 'react-bootstrap';
// import Tabs from 'react-bootstrap/Tabs';
// import Tab from 'react-bootstrap/Tab';
// import 'bootstrap/dist/css/bootstrap.min.css';
import MetaTags from 'react-meta-tags';
import {isMobile} from 'react-device-detect';
import { Table } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
const propTypes = {
...SectionProps.types
}
const defaultProps = {
...SectionProps.defaults
}
const Hero = ({
className,
topOuterDivider,
bottomOuterDivider,
topDivider,
bottomDivider,
hasBgColor,
invertColor,
...props
}) => {
const [isItMobile, setIsItMobile] = useState(true)
const [kpi, setKpi] = useState([])
const [chosenDate, setChosenDate] = useState('NONE')
const [chosenFourKubeBalance, setChosenFourKubeBalance] = useState('1000')
const [chosenVOOBalance, setChosenVOOBalance] = useState('1000')
const [stockList, setStockList] = useState([])
const [buyingPriceList, setBuyingPriceList] = useState([])
const [sellingPriceList, setSellingPriceList] = useState([])
const [percentChangeList, setPercentChangeList] = useState([])
const [averagePercChange, setAveragePercChange] = useState('0')
//for return values in text
const [yourBal, setYourBal] = useState('')
const [fourkubeROI, setFourkubeROI] = useState('')
const [vooROI, setVooROI] = useState('')
const [yourPerfAboveVOO, setYourPerfAboveVOO] = useState('')
const [yourExtraMoney, setYourExtraMoney] = useState('')
//for the performance table
const [dateList, setDateList] = useState([])
const [fourKubeBal_list, setFourKubeBal_list] = useState([])
const [averagePercentReturn_list, setAveragePercentReturn_list] = useState([])
const [cumulativePercentReturn_list, setCumulativePercentReturn_list] = useState([])
const [voo_list, setVoo_list] = useState([])
const [voo_Challenge_list, setVoo_Challenge_list] = useState([])
const [ticker_list, setTicker_list] = useState([])
var requestOptions = {
method: 'POST',
headers: { 'Content-Type': 'application/json' },
};
useEffect(() => {
if (isMobile === false) {
setIsItMobile(false)
}
requestOptions["body"] = JSON.stringify({})
fetch("https://4ih0epznf8.execute-api.us-east-2.amazonaws.com/".concat('fourkubeStage'))
.then(response => response.json())
.then(data => {
setKpi(data.dataList)
console.log("NEWWWW")
console.log(data.dataList)
let last4KBal = data.dataList[0].yFOURKUBE[data.dataList[0].yFOURKUBE.length - 1]
let lastVOOBal = data.dataList[0].yVOO[data.dataList[0].yFOURKUBE.length - 1]
let ROI4K = ((last4KBal-1000)/1000) * 100
let ROIVOO = ((lastVOOBal-1000)/1000) * 100
console.log("LAST ROI")
setFourkubeROI(ROI4K.toFixed(2).concat('%'))
setYourBal(last4KBal)
setVooROI(ROIVOO.toFixed(2).concat('%'))
console.log(ROI4K - ROIVOO)
setYourPerfAboveVOO((ROI4K - ROIVOO).toFixed(2).concat('%'))
setYourExtraMoney((last4KBal - lastVOOBal).toFixed(2))
console.log(typeof(data.dataList[0].yFOURKUBE))
console.log(data.dataList[0].yFOURKUBE.length)
console.log(data.dataList[0].yFOURKUBE[247])
setDateList(data.dataList[0].x)
setFourKubeBal_list(data.dataList[0].yFOURKUBE)
setAveragePercentReturn_list(data.dataList[0].averagePercentReturn_list)
setCumulativePercentReturn_list(data.dataList[0].cumulativePercentReturn_list)
setVoo_list(data.dataList[0].voo_list)
setVoo_Challenge_list(data.dataList[0].vooChallenge_list)
setTicker_list(data.dataList[0].text)
})
}, []);
function displayNotesForGraph(e) {
let theDate = e.points[0].x
setChosenDate(theDate)
let fourKubeBal = e.points[0].data.yFOURKUBE[e.points[0].pointIndex].toFixed(2)
setChosenFourKubeBalance(fourKubeBal)
let VOOBal = e.points[0].data.yVOO[e.points[0].pointIndex].toFixed(2)
setChosenVOOBalance(VOOBal)
//call buy and sell API
console.log("FLAG 1")
var requestOptions = {
method: 'POST',
headers: { 'Content-Type': 'application/json' }
};
fetch("https://ab8dz5kmac.execute-api.us-east-2.amazonaws.com/fourkubeStage?theDate=".concat(theDate), requestOptions)
.then(response => response.json())
.then(data => {
console.log(data)
console.log('Flag 2')
console.log(data.stockList)
let theStockList = data.stockList
setStockList(theStockList)
if (theStockList[0] !== 'NONE') {
setBuyingPriceList(data.buyingPriceList)
setSellingPriceList(data.sellingPriceList)
setPercentChangeList(data.percChangeList)
setAveragePercChange(data.averagePercChange.toFixed(2))
}
})
}
const [videoModalActive, setVideomodalactive] = useState(false);
const openModal = (e) => {
e.preventDefault();
setVideomodalactive(true);
}
const closeModal = (e) => {
e.preventDefault();
setVideomodalactive(false);
}
const outerClasses = classNames(
'hero section center-content',
topOuterDivider && 'has-top-divider',
bottomOuterDivider && 'has-bottom-divider',
hasBgColor && 'has-bg-color',
invertColor && 'invert-color',
className
);
const innerClasses = classNames(
'hero-inner section-inner',
topDivider && 'has-top-divider',
bottomDivider && 'has-bottom-divider'
);
return (
<>
<section
   {...props}
   className={outerClasses}
   >
   <div className="container-sm">
      <div className={innerClasses}>
         <div className="hero-content">
            <div className="container-xs">
               <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
               
               <h3 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
               <span className="text-color-primary">Proven</span> With Real-Life <span className="text-color-primary">Transparent Results</span> since 2020
               </h3>
               Performance Metrics since 7/30/20, <i>updated daily at 12pm EST.</i>
               <br></br> <left>•&nbsp;&nbsp;FourKube ROI: <span className="text-color-primary">+{fourkubeROI}</span><br></br>
               •&nbsp;&nbsp;S&P 500 ROI: <span className="text-color-warning">+{vooROI}</span></left>
               <br></br><br></br>
               Starting with <b>$1,000</b> on <b>7/30/20</b>, FourKube's AI algorithm has traded the portfolio value up to <span className="text-color-primary"><b>+${yourBal}</b></span>,

        
               making an extra <span className="text-color-primary"><b>+${yourExtraMoney}</b></span> over the S&P 500 during the same time period.
               <br></br><br></br>See our AI's trading performance in the chart down below. Watch us trade along side you on our&nbsp;
               <a href="https://www.instagram.com/fourkube"
                  target="_blank"
                  rel="noopener noreferrer"><u>Instagram</u></a>
               .
               <br></br><br></br>
               </p>
               <div className="reveal-from-bottom" data-reveal-delay="600">
                  <ButtonGroup>
                     <Link to="Subscribe">
                     <Button tag="a" color="primary" >
                     Subscription Options
                     </Button>
                     </Link>&nbsp;&nbsp;&nbsp;
                     <Link to="Performance_Table">
                  <Button tag="a" color="dark" to="Performance_Table">
                    Trading History in Table Form
                    </Button>
                    </Link>
                  </ButtonGroup>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>
<center>
   <i>(Click a point on the graph line to see trade details for that day below graph.
   <br></br>Graph is optomized for desktop, not mobile. Expect a short delay for the first click response.)</i>
   <Plot
      data = {kpi}
      graphDiv="graph"
      onClick = {(e) => displayNotesForGraph(e)}
      style={{ width: '100%' }}
      layout={{
         
         autosize:true,
         xaxis:{color:'white'},
         yaxis:{title:'Balance in $US', color: 'white'},
         paper_bgcolor: '#282c34',
         plot_bgcolor: '#282c34',
         title:{text: "FourKube vs Vanguard S&P 500 ETF <br><sup>($1000 Starting Balance on 7/30/20)</sup>", font:{color:'white'}},
         legend: {font:{color: 'white'}, x: 0, y: 1.075}
      }}
      config={{ "displaylogo": false,modeBarButtonsToRemove: ['Zoom','Pan'] }}
   /> 
</center>
<br></br>
{
(chosenDate==='NONE') ? null :
<center>
   <div style={{ padding: 20}}>
   <p>
      <u>Date chosen:</u> <b>{chosenDate}</b><br></br>
      FourKube Balance: <b>${chosenFourKubeBalance}</b> <br></br>
      Vanguard S&amp;P 500 Balance: <b>${chosenVOOBalance}</b>
   </p>
   </div>
   {(stockList[0]==='NONE') ? <div style={{ padding: 20}}>
   <p><b>(FourKube sold no stocks on {chosenDate})</b></p>
   </div> :<>
   <div style={{ padding: 20}}>
   <Table size="xl" striped bordered hover variant="dark" style={{width:'40%'}} >
      <thead>
         <tr style={{width:'75%'}}>
            <th style={{'text-align':'center'}}>Stock List</th>
            <th style={{'text-align':'center'}}>Buying Price (24 hrs earlier)</th>
            <th style={{'text-align':'center'}}>Selling Price on {chosenDate}</th>
            <th style={{'text-align':'center'}}>Percent Change</th>
         </tr>
      </thead>
      <tbody style={{width:'75%'}}>
         {stockList.map((stock, i) => (
         <>
         <tr>
            <td style={{'text-align':'center'}}>{stock}</td>
            <td style={{'text-align':'center'}}>${buyingPriceList[i]}</td>
            <td style={{'text-align':'center'}}>${sellingPriceList[i]}</td>
            <td style={{'text-align':'center'}}>{percentChangeList[i]}%</td>
         </tr>
         </>
         ))}
      </tbody>
   </Table>
   <div>Average Percent Change on {chosenDate}: <b>{averagePercChange}%</b></div>
   <small>(FourKube buys stocks 24 hours before they are sold. Both buying and selling occur at ~11am CST)</small><br></br>
   </div>
   </>}
</center>
}
<br></br>
<section
   {...props}
   className={outerClasses}
   >
   <div className="container-sm">
      <div className={innerClasses}>
         <div className="hero-content">
            <div className="container">
            <div id="ai-sub">
            <h3 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
                  We Trade <span className="text-color-primary">Alongside</span> You
               </h3>
               We as founders trade alongside you. 
               Every time the algorithm generates a prediction, FourKube will post a time-stamped screen recording of the founders investing ~$10,000 into the suggested trade on our &nbsp;
               <a href="https://www.instagram.com/fourkube"
                  target="_blank"
                  rel="noopener noreferrer"><u>Instagram</u></a> story, ensuring transparency and trust, further conveying our confidence in the algorithm.
               <br></br><br></br>
               <h3 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
                  Subscription <span className="text-color-primary">Benefits</span>
               </h3>
               <ol style={{textAlign: 'left'}} className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
               <li>All FourKube predictions sent via email from our successfully backtested algorithm</li>
               <li>Optional text alerts, informing you when the new prediction has been emailed so you can buy the stock prediction at the right time</li>
               <li>Free updates to the FourKube AI Algorithm. The subscription fee will increase with these updates for future subscribers, but once bought your price is locked in and you get these updates for free</li>
               <li>Support around the clock. Quick responses to questions or concerns via email or Instagram DM.</li>
               <li>Access to our upcoming private Discord. Reach out to us if you want to sign up for early access.</li>
               </ol>
               <br></br>
               <br></br>
               <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
               As our AI algorithm improves over time, the subscription fee will increases as well.
               However, you can lock in a lower rate now to get access to FourKube Gold algorithm updates for free to avoid paying extra later on.
               Predictions come in Monday-Thursday via email.
               Cancel subscription at anytime via email or Instagram DM.
               Past payments are non-refundable.
              </p>
               <br></br>
               <br></br>
               <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                  <span className="text-color-primary">FourKube GOLD (Quarterly)</span><br></br>
                  <b>$59.99/quarter <strike>$79.99/quarter</strike></b> (Limited-time sale)<br></br>
                  Cancel anytime. &nbsp;
                  <Link to="Terms-and-Conditions-of-Use">
                  <a
                     className="App-link"
                     target="_blank"
                     rel="noopener noreferrer">
                  Offer terms
                  </a>
                  </Link>
                  &nbsp;apply.
                  <br></br>
                  <Button tag="a" color="primary" size="lg" onClick = {()=> window.open("https://buy.stripe.com/14kcOy2wTfhH50IaEK", "_blank")}>Buy Quarterly Subscription</Button>
               </p>
               <br></br>
            </div>
            </div>
         </div>
      </div>
   </div>
</section>
<br></br>
<br></br>
</>
);
}
Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;
export default Hero;
