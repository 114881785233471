import React from 'react';
import Cta from '../components/sections/Cta';
import PerformanceHistory from '../components/sections/PerformanceHistory';
import ReactGA from 'react-ga';

ReactGA.initialize(process.env.REACT_APP_GA_CODE);

const trackPage = page => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
};

const Performance = () => {

  return (
    <>
      <PerformanceHistory className="illustration-section-01" />
      {/* <FeaturesTiles />
      <FeaturesSplit invertMobile topDivider imageFill className="illustration-section-02" />
      <Testimonial topDivider /> */}
      <Cta split />
    </>
  );
}

export default Performance;
