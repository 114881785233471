import React from 'react';
import Cta from '../components/sections/Cta';
import Algorithm_page from '../components/sections/Algorithm';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import Testimonial from '../components/sections/Testimonial';
import ReactGA from 'react-ga';

ReactGA.initialize("G-51D0862BGM");

const trackPage = page => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
};

const Algorithm = () => {

  return (
    <>
      <Algorithm_page className="illustration-section-01" />
      <FeaturesTiles />
      <Testimonial topDivider />
      <Cta split />
    </>
  );
}

export default Algorithm;
