import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import Image from '../elements/Image';


const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: "FourKube's Mission",
    paragraph: 'FourKube strives to help the average trader leverage big data and artificial intelligence to make profitable trades. Studies show that around  80% or more of individual traders lose money.  Hedge funds are able win because they have access to information that the general public don’t have and are able to leverage this with big data and artificial intelligence to come out profitable. FourKube has consistently proven to be part of the 20% who profit for over a year now. Not only is the FourKube’s algorithm profitable, but it has been greatly outperforming market indexes including the Dow Jones, SP500, and NASDAQ. After years of using our money in the FourKube’s AI algorithm, we have released it for everyone to see its trades and results. '
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          {/* <SectionHeader data={sectionHeader} className="center-content" /> */}
          <div className={tilesClasses}>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/feature-tile-icon-01.svg')}
                      alt="Features tile icon 01"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Performance
                    </h4>
                  <p className="m-0 text-sm">
                  Not only is FourKube’s AI algorithm highly profitable, but it also has been greatly outperforming market indexes including the Dow Jones, S&P500, and NASDAQ.
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/feature-tile-icon-02.svg')}
                      alt="Features tile icon 02"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Ease Of Use
                    </h4>
                  <p className="m-0 text-sm">
                    Following the algorithm is very simple. Every weekday at 12pm (EST), buy the stock(s) emailed to you at that time. Then sell after 24 hours. Rinse and repeat.
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/feature-tile-icon-03.svg')}
                      alt="Features tile icon 03"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Full Transparency
                    </h4>
                  <p className="m-0 text-sm">
                    We show every trade our AI makes and never hide any losses. You can see the performance and full trading history&nbsp;<a className="App-link"
                                href="https://www.curiousgnu.com/day-trading"
                                target="_blank"
                                rel="noopener noreferrer"><u>here</u></a>&nbsp;for yourself. These stats are updated daily.
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/feature-tile-icon-04.svg')}
                      alt="Features tile icon 04"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    AI-Powered, Data-Driven
                    </h4>
                  <p className="m-0 text-sm">
                  Utilizing cutting-edge AI and statistical models, FourKube’s stock prediction algorithm leverages large amounts of data to make its decisions. Check out our Youtube channel &nbsp;
                    <a className="App-link"
                    href="https://www.youtube.com/channel/UCoIn8R9xIVGrhEBjMbgv-qA"
                    target="_blank"
                    rel="noopener noreferrer">
                    <u>FourKube</u></a>
                    &nbsp;to learn more on our data, statistics, and AI algorithm.
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/feature-tile-icon-05.svg')}
                      alt="Features tile icon 05"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Backtested Weekly
                    </h4>
                  <p className="m-0 text-sm">
                  Backtesting evaluates the effectiveness of a trading strategy by running it against historical data to see how it would have fared. FourKube's algorithm has been successfully backtested to prove its effectiveness. Our data engineers and scientiest do this on a daily basis to fine tune our algorithm to perform its best.
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/feature-tile-icon-06.svg')}
                      alt="Features tile icon 06"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Quick and Reliable Customer Service 24/7
                    </h4>
                  <p className="m-0 text-sm">
                  We will respond within a day on any inquiry over email or Instagram. All questions and concerns welcome. A community discord is also coming soon.
                    </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;
