import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Input from '../elements/Input';
import MailchimpSubscribe from "react-mailchimp-subscribe";
import Button from '../elements/Button';

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool
}

const defaultProps = {
  ...SectionProps.defaults,
  split: false
}


const Cta = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {

  

  const outerClasses = classNames(
    'cta section center-content-mobile reveal-from-bottom',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'cta-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
    split && 'cta-split'
  );  
  const url = "https://fourkube.us4.list-manage.com/subscribe?u=c8c819cf3b29960cc487a05c0&id=79a3aef62e"
  const SimpleForm = () => <MailchimpSubscribe url={url}/>
  const CustomForm = () => (
    <MailchimpSubscribe
      url={url}
      render={({ subscribe, status, message }) => (
        <div>
          <SimpleForm onSubmitted={formData => subscribe(formData)} />
          {status === "sending" && <div style={{ color: "blue" }}>sendiasdfasdfang...</div>}
          {status === "error" && <div style={{ color: "red" }} dangerouslySetInnerHTML={{__html: message}}/>}
          {status === "success" && <div style={{ color: "green" }}>Subscribed !</div>}
        </div>
      )}
    />
  )




  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div
          className={innerClasses}
        >
          <div className="cta-slogan">
            <h4 className="m-0">
              Instagram/TikTok/Youtube: @FourKube
            </h4>
            <h4 className="m-0">
              Email: fourkube4@gmail.com
            </h4>
            <h4 className="m-0">
              Discord Link: https://discord.gg/kaqDbvt8
            </h4>
            <br></br>

           
           {/* <MailchimpSubscribe url={url} /> */}
            <h5>Sign up here for exclusive offers and inside information </h5>
           <div className="cta-action">
           <Button tag="a" color="dark" to="Performance_Graph" onClick={() => window.open("https://fourkube.us4.list-manage.com/subscribe?u=c8c819cf3b29960cc487a05c0&id=79a3aef62e")}>
                    Exclusive List
                    </Button>
                    </div>

           
           
           
          </div>
          {/* <div className="cta-action">
            <Input id="newsletter" type="email" label="Subscribe" labelHidden hasIcon="right" placeholder="Your best email">
              <svg width="16" height="12" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 5H1c-.6 0-1 .4-1 1s.4 1 1 1h8v5l7-6-7-6v5z" fill="#376DF9" />
              </svg>
            </Input>


          </div> */}
        </div>
      </div>
    </section>
  );
}

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;