import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Button from '../elements/Button';
import SectionHeader from './partials/SectionHeader';

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool
}

const defaultProps = {
  ...SectionProps.defaults,
  split: false
}

const sectionHeader = {
    title: 'Unsubscribe Request',
    paragraph: 'Please submit your email and cancellation reasoning below. You will receive an email after you have successfully unsubscribed.'
  };

const inputStyle = {
width: '100%', // Adjust as needed
backgroundColor: '#f8f8f8 !important', // Using !important to override
borderColor: 'transparent', // Optional: to remove or style the border
padding: '10px', // Optional: for better text spacing
// Other styles like border-radius can be added here
};
// const customStyle = {
// marginTop: '-150px', // Reduces space at the top
// marginBottom: '-20px', // Reduces space at the bottom
// };

const UnsubscribeSection = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {
  const [email, setEmail] = useState('');
  const [feedback, setFeedback] = useState('');
  const [successMessage, setSuccessMessage] = useState(''); // New state for success message

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  }

  const handleFeedbackChange = (e) => {
    setFeedback(e.target.value);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (feedback.length < 16) {
        setSuccessMessage('Feedback must be at least 16 characters long.');
        return; // Prevent form submission
      }
      
    try {
      const response = await fetch('https://1jmmtiry31.execute-api.us-east-2.amazonaws.com/prod', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, feedback }),
      });
      const responseData = await response.json();
      // Handle success
      setEmail(''); // Clear email input
      setFeedback(''); // Clear feedback textarea
      setSuccessMessage('Unsubscribe request sent successfully! You should hear back within 1 business day.'); // Show success message
    } catch (error) {
      console.error('Error:', error);
      // Handle error
      setSuccessMessage(''); // Ensure success message is not displayed on error
    }
  }

  const outerClasses = classNames(
    'feedback-section section center-content-mobile reveal-from-bottom',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'feedback-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
    split && 'feedback-split'
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <form onSubmit={handleSubmit}>
            <div>
              <label htmlFor="email">Email Address:</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={handleEmailChange}
                required
                style={inputStyle}
              />
            </div>
            <br />
            <div>
              <label htmlFor="feedback">Cancellation Reason:</label>
              <textarea
                id="feedback"
                value={feedback}
                onChange={handleFeedbackChange}
                required
                rows="5"
                minLength="16"
                style={{ ...inputStyle, height: 'auto' }}
              />
            </div>
            <Button type="submit">Submit Feedback</Button>
          </form>
          {successMessage && <div className="success-message">{successMessage}</div>} {/* Display success message */}
        </div>
      </div>
    </section>
  );
}

UnsubscribeSection.propTypes = propTypes;
UnsubscribeSection.defaultProps = defaultProps;

export default UnsubscribeSection;
