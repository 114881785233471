import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import MetaTags from 'react-meta-tags';
import {isMobile} from 'react-device-detect';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const [isItMobile, setIsItMobile] = useState(true)
  const [kpi, setKpi] = useState([])
  const [chosenDate, setChosenDate] = useState('NONE')
  const [chosenFourKubeBalance, setChosenFourKubeBalance] = useState('1000')
  const [chosenVOOBalance, setChosenVOOBalance] = useState('1000')
  const [stockList, setStockList] = useState([])
  const [buyingPriceList, setBuyingPriceList] = useState([])
  const [sellingPriceList, setSellingPriceList] = useState([])
  const [percentChangeList, setPercentChangeList] = useState([])
  const [averagePercChange, setAveragePercChange] = useState('0')

  //for the performance table
  const [dateList, setDateList] = useState([])
  const [fourKubeBal_list, setFourKubeBal_list] = useState([])
  const [averagePercentReturn_list, setAveragePercentReturn_list] = useState([])
  const [cumulativePercentReturn_list, setCumulativePercentReturn_list] = useState([])
  const [voo_list, setVoo_list] = useState([])
  const [voo_Challenge_list, setVoo_Challenge_list] = useState([])
  const [ticker_list, setTicker_list] = useState([])

  var requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  };
  useEffect(() => {
    if (isMobile === false) {
      setIsItMobile(false)
    }
    requestOptions["body"] = JSON.stringify({})
    fetch("https://4ih0epznf8.execute-api.us-east-2.amazonaws.com/".concat('fourkubeStage'))
    .then(response => response.json())
    .then(data => {
      setKpi(data.dataList)
      setDateList(data.dataList[0].x)
      setFourKubeBal_list(data.dataList[0].yFOURKUBE)
      setAveragePercentReturn_list(data.dataList[0].averagePercentReturn_list)
      setCumulativePercentReturn_list(data.dataList[0].cumulativePercentReturn_list)
      setVoo_list(data.dataList[0].voo_list)
      setVoo_Challenge_list(data.dataList[0].vooChallenge_list)
      setTicker_list(data.dataList[0].text)
    })
  }, []);

  function displayNotesForGraph(e) {
    let theDate = e.points[0].x
    setChosenDate(theDate)
    let fourKubeBal = e.points[0].data.yFOURKUBE[e.points[0].pointIndex].toFixed(2)
    setChosenFourKubeBalance(fourKubeBal)
    let VOOBal = e.points[0].data.yVOO[e.points[0].pointIndex].toFixed(2)
    setChosenVOOBalance(VOOBal)


    //call buy and sell API
    var requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    };
    fetch("https://morning-brushlands-83469.herokuapp.com/https://bmcwjklg92.execute-api.us-east-2.amazonaws.com/test?theDate=".concat(theDate), requestOptions)
    .then(response => response.json())
    .then(data => {
      let theStockList = JSON.parse(data.body).stockList
      setStockList(theStockList)
      if (theStockList[0] !== 'NONE') {
        setBuyingPriceList(JSON.parse(data.body).buyingPriceList)
        setSellingPriceList(JSON.parse(data.body).sellingPriceList)
        setPercentChangeList(JSON.parse(data.body).percChangeList)
        setAveragePercChange(JSON.parse(data.body).averagePercChange.toFixed(2))
      }
    })

}

  const [videoModalActive, setVideomodalactive] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setVideomodalactive(true);
  }

  const closeModal = (e) => {
    e.preventDefault();
    setVideomodalactive(false);
  }

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <>
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
            <span className="text-color-primary">FourKube </span> Terms <span className="text-color-primary">And </span>Conditions <span className="text-color-primary">Of </span> Use
            </h1>
            <br></br><br></br>
            <div className="container">
                <p>This website located at www.fourkube.info (the “Website”) is a service of FourKube LLC (together with its affiliates “FourKube”, “us” or “we”). These Terms and Conditions of Use (“Terms of Use”) set forth the terms and conditions applicable to your access to and use of the Website.

THESE TERMS OF USE DESCRIBE YOUR RIGHTS AND RESPONSIBILITIES IN CONNECTION WITH YOUR USE OF ANY INFORMATION, CONTENT, MESSAGE, PRODUCT, SERVICE, SOFTWARE AND DATABASE AVAILABLE THROUGH THE WEBSITE. PLEASE READ THEM CAREFULLY BEFORE ACCESSING OR USING THE WEBSITE. BY ACCESSING ANY PORTIONS OF THIS WEBSITE, YOU AGREE THAT YOU HAVE READ AND UNDERSTAND THESE TERMS OF USE AND AGREE TO BE BOUND AND ABIDE BY THEM. IF YOU DO NOT AGREE TO ALL OF THE TERMS OF USE YOU ARE NOT AUTHORIZED TO USE THIS WEBSITE. WE MAY REVISE THESE TERMS OF USE AT ANYTIME, WITHOUT PRIOR NOTICE. YOU ARE RESPONSIBLE FOR PERIODICALLY REVIEWING THE TERMS OF USE FOR APPLICABLE CHANGES. YOUR CONTINUED ACCESS AND/OR USE OF THE WEBSITE FOLLOWING ANY CHANGE CONSTITUTES YOUR AGREEMENT TO ANY SUCH REVISED TERMS OF USE.
<br></br><br></br><ol style={{textAlign: 'left'}} className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">

<li>General Use and Restrictions. The Website and the information contained therein is intended for information purposes only and is provided as a service to FourKube clients, investors, and other interested parties. No portion of the Website should be considered a solicitation to buy or an offer to sell a security, or any other product or service, to any person in any jurisdiction where such offer, solicitation, purchase or sale would be deemed unlawful under the securities laws of such jurisdiction. The Website is not intended to provide tax, legal, investment or insurance advice. You are solely responsible for determining whether the product and services described on the Website are appropriate for your specific situation. You should consult an attorney or tax professional regarding your legal or tax situation prior to acting based on information obtained from the Website.</li>

<li>Limited License; Restrictions on Use of Website. Subject to your agreement to abide by these Terms of Use in their entirety, as well as any other rules, procedures, policies, terms and conditions that govern all or a portion of the Website, FourKube grants you a limited, revocable, nonexclusive, nontransferable license to view, store, bookmark, download and print the content within the Website for your personal, informational, and noncommercial use or as expressly authorized by FourKube in writing. You agree not to access or use the Website for any other purpose including, without limitation, any purpose that is prohibited by these Terms of Use or that is otherwise unlawful. You are not authorized to alter, modify, or create derivative works of any such content without first obtaining our express written permission. You further agree not to, without first obtaining our express written permission, (a) use any of our trademarks as metatags on other websites, (b) use the Website in any manner that is illegal or impairs its operation or availability or usage by others, (c) display any part of the Website in frames (or any content thereof via in-line links), or (d) use or access, or attempt to use or access, any portion of the Website for which registration is required unless you are a Registered User (as defined in Section 3 below).</li>

<li>Restricted Area. Certain portions of the Website may be accessible only to users that register to use those portions of the Website (“Registered Users”). Unauthorized use of the restricted portions of the Website (“Restricted Area”), including, but not limited to, unauthorized entry, misuse of usernames or passwords or misuse of any information contained in the Restricted Area, is strictly prohibited. You may not attempt to gain unauthorized access to the Restricted Area through hacking, password mining or any other means. If you register to use the Restricted Area, you will be issued a username and password to access the Restricted Area. However, we may terminate your status as a Registered User for any reason (or no reason) with or without notice to you, and you agree not to access the Restricted Area at any time after such termination. Termination of your access to and use of the Website shall not relieve you of any obligations arising or accruing prior to such termination or limit any liability that you otherwise may have to FourKube.</li>

You agree to protect your username and password by, among other things, keeping them confidential and not sharing them with any other person except your directors, trustees, employees, representatives, agents, and financial, legal or tax advisors, and others with whom you share investment decisions and who need to know such information. You agree to notify us immediately of any suspicious or unauthorized use of your username, password or account. FourKube has not obligation to inquire as to the authority or propriety of any use or action taken under your password and will not be responsible for any loss to you arising from any such use or action or from your failure to comply with the above.

<li>Performance. Any performance data expressed in the Website is an indication of past performance and does not indicate or guarantee future results. FourKube advises all users of the Website that there is a possibility of loss on all investments and investor principal is not guaranteed. Additionally, FourKube engages in certain strategies that may involve investments that are illiquid and may be subject to heightened risk of loss, as such these investments may not be suitable for many investors.</li>

<li>Intellectual Property. The Website and its content are protected by applicable copyright, trademark, unfair competition and other laws and may not be used, copied, distributed, imitated or posted, in whole or in part, except as expressly provided herein. Except as otherwise expressly provided in these Terms of Use, all rights in and to the Website and content thereof are expressly reserved by FourKube.</li>

<li>Third Party Service Providers. FourKube may use third party service providers, vendors, and licensors to assist in providing you with the web-based account service for the Website (the “Service Providers”). You hereby agree that the terms and conditions herein, including any warranty and liability disclaimers, inure to the benefit of such Service Providers and that such Service Providers are deemed to be third party beneficiaries of these Terms of Use.</li>

<li>Links to External Websites. We may provide hyperlinks to other websites and Internet resources operated by third parties. We have no control over such websites and resources or their privacy policies. Such hyperlinks are provided for your reference only and their inclusion does not imply any sponsorship, affiliation or endorsement of the material on such websites or with their operators. Material contained in linked websites does not necessarily reflect the views of FourKube and we provide no warranty or representation in relation to the accuracy or completeness of such material.</li>

<li>Links to this Website from other Websites. You shall not display hyperlinks on your websites to any website owned or operated by FourKube. Access to any FourKube website does not authorize you to use any of FourKube names, logos, trademarks or copyrighted material, and you agree not to do so without FourKube express written consent.</li>

<li>Submissions. You agree that no comments or materials sent to FourKube, including feedback data such as completed questionnaires, forms, questions, comments, or suggestions (collectively “Feedback”) submitted by you to this Website will violate any rights of any third party, including copyright, trademark, privacy, confidentiality, or other personal or proprietary rights. You are and shall remain solely responsible for the content of any Feedback you submit.</li>

<li>Monitoring activity on the Website. FourKube may: (a) monitor and record activity on the Website for any reason or for no reason; (b) investigate any complaint or reported violation of our policies; (c) report any activity that we suspect may violate any law or regulation to appropriate regulatory authorities; or (d) suspend or terminate use of the Website, deny access to all or part of the Website or take any other action that we deem appropriate.</li>

<li>No Warranties; Limitation of Liability. The information contained in the Website is provided on an “as is” and “as available” basis. FourKube may, in its sole discretion and at any time, discontinue the Website, or any part thereof, with or without notice. We assume no liability or responsibility for any errors or omissions in respect of the Website. Further, we do not represent or warrant that any aspect of the Website will work properly or will be continuously available. We hereby disclaim any and all warranties and representations, whether express or implied, oral or written, including, without limitation, any and all implied warranties of merchantability, reasonable care, security, quality, timeliness, availability, completeness, reliability, accuracy, and/or fitness for a particular purpose, in each instance in respect of the Website. Furthermore, we expressly disclaim any and all warranties of title and/or non-infringement in respect of the Website. Some jurisdictions do not allow the disclaimer of implied warranties, in which case this provision may not apply to you.</li>

Except where prohibited by law, in no event will FourKube be liable for (a) any damages of any nature whatsoever resulting from, or related to, the loss, delay or inability to use the Website, the loss of any content obtained through the Website, or the inaccuracy of any content, or (b) any indirect, consequential, special, incidental or punitive damages whatsoever, including, without limitation, damages for loss of profits arising out of or in any way connected with these Terms of Use or otherwise arising out of the use or performance of the Website, in each of (a) and (b) above whether based in contract, tort, negligence, strict liability or otherwise.

<li>Indemnity. You agree to indemnify, defend and hold FourKube and its officers, agents, partners, and employees, harmless from and against any and all claims, demands, losses, and expenses, including penalties, interest, and attorneys’ fees, incurred by us in connection with any claim made by a third party due to or arising out of your Feedback, your use of the Website, including any use by your employees, or your breach of these Terms of Use, including, but not limited to, your violation or infringement of copyrights, trademarks, or any other proprietary rights.</li>

<li>Force Majeure. FourKube shall not be liable for any failure or delay in its performance under these Terms of Use that is due to any event beyond its reasonable control, including without limitation, fire, explosion, unavailability of utilities, Internet delays and failures, telecommunication failures, unavailability of components, labor difficulties, war, riot, act of God, judgment or government instructions.</li>

<li>Entire Agreement. These Terms of Use including, without limitation, any other terms and conditions that may appear on the Website from time-to-time, contain the full understanding with respect to your use and access of the Website.</li>

<li>Severability. If any portion of these Terms of Use is held to be invalid or unenforceable by a court of competent jurisdiction, the invalid or unenforceable portion shall be modified in accordance with the applicable law as nearly as possible to reflect the original intention of the applicable provision, and the remainder of these Terms of Use shall remain in full force and effect.</li>

<li>Waiver. The failure of FourKube to insist upon or enforce strict performance by you of any provision of these Terms of Use shall not be construed as a waiver of any provision or right. None of our rights or remedies conferred by these Terms of Use is exclusive of any other right or remedy conferred herein or by law or in equity. If we bring any suit against you to enforce these Terms of Use or otherwise in connection with your use and access of the Website, you agree that if we prevail in such suit we shall be entitled to recover all costs and expenses incurred in such suit including reasonable attorney fees.</li>

<li>Assignment. You may not transfer any rights or obligations you may have to your account or under these Terms of Use without our prior written consent.</li>

<li>Applicable Law. These Terms of Use, and all matters arising out of or relating to the Website, shall be governed by the laws of the United States and the Commonwealth of Massachusetts, without giving effect to the conflict of law provisions thereof. Any suit against us for claims arising out of or relating to the Website or these Terms of Use must be brought in the federal courts sitting in Boston, Massachusetts unless no federal subject matter jurisdiction exists, in which case such suit must be brought in the state courts sitting in Boston, Massachusetts.</li>

<li>Limitation of Actions. Regardless of any statute or law to the contrary, no claim or cause of action arising out of or related to use of the Website or the Terms of Use may be brought more than one (1) year after such claim or cause of action has arisen.</li></ol></p>
 <br></br>



                <div className="reveal-from-bottom" data-reveal-delay="600">
                <ButtonGroup>
                  <Link to="Subscribe">
                  <Button tag="a" color="primary" >
                    Subscribe
                    </Button>
                    </Link>&nbsp;&nbsp;&nbsp;
                    <Link to="Performance_graph">
                  <Button tag="a" color="dark" to="Performance_Graph">
                    Performance
                    </Button>
                    </Link>
                </ButtonGroup>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

                  </>
  );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
