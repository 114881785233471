import React, { useState } from 'react';

const PasswordProtected = ({ children }) => {
  const [password, setPassword] = useState('');
  const [accessGranted, setAccessGranted] = useState(false);

  const correctPassword = '4kubepass'; // Set your password here

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password === correctPassword) {
      setAccessGranted(true);
    } else {
      alert('Incorrect Password'); // Or handle the error as you see fit
    }
  };

  if (accessGranted) {
    return <div>{children}</div>;
  }

  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '80vh', // Adjust the height as needed
    textAlign: 'center'
  };

  const formStyle = {
    // Add styles for your form if needed
  };


  return (
    <div style={containerStyle}>
      <form onSubmit={handleSubmit} style={formStyle}>
        <label>
          Password:
          <input 
            type="password" 
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
          />
        </label>
        <button type="submit">Enter</button>
      </form>
    </div>
  );
};

export default PasswordProtected;
