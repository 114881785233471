import React, { useRef, useEffect } from 'react';
import { useLocation, Switch, HashRouter } from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';
import ReactGA from 'react-ga';
import {
  Route
} from "react-router-dom";

// Layouts
import LayoutDefault from './layouts/LayoutDefault';

// Views
import Home from './views/Home';
import Performance from './views/Performance';
import Performance_table from './views/Performance_table';
import FAQ from './views/FAQ';
import Terms from './views/Terms';
import Subscribe from './views/Subscribe';
import Risk from './views/Risk';
import Algorithm from './views/Algorithm';
import SubscribersPage from './views/SubscribersPage'

// Initialize Google Analytics
ReactGA.initialize(process.env.REACT_APP_GA_CODE);

const trackPage = page => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
};

const App = () => {

  const childRef = useRef();
  let location = useLocation();

  useEffect(() => {
    const page = location.pathname;
    document.body.classList.add('is-loaded')
    childRef.current.init();
    trackPage(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <HashRouter>
    <ScrollReveal
      ref={childRef}
      children={() => (
        <Switch>
          <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
          <AppRoute exact path="/Algorithm/" component={Algorithm} layout={LayoutDefault} />
          <AppRoute exact path="/Performance_Graph/" component={Performance} layout={LayoutDefault} />
          <AppRoute exact path="/Performance_Table/" component={Performance_table} layout={LayoutDefault} />
          <AppRoute exact path="/FAQ/" component={FAQ} layout={LayoutDefault} />
          <AppRoute exact path="/Subscribe/" component={Subscribe} layout={LayoutDefault} />
          <AppRoute exact path="/Risk/" component={Risk} layout={LayoutDefault} />
          <AppRoute exact path="/SubscribersOnly/" component={SubscribersPage} layout={LayoutDefault} />
          <AppRoute exact path="/Terms-and-Conditions-of-Use/" component={Terms} layout={LayoutDefault} />
        </Switch>
      )} />
      </HashRouter>
  );
}

export default App;
