import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Image from '../elements/Image';
import Modal from '../elements/Modal';
import {isMobile} from 'react-device-detect';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../App.css';
import ImageGrid from './ImageGrid';

const propTypes = {
...SectionProps.types
}
const defaultProps = {
...SectionProps.defaults
}
const Hero = ({
className,
topOuterDivider,
bottomOuterDivider,
topDivider,
bottomDivider,
hasBgColor,
invertColor,
...props
}) => {
const [isItMobile, setIsItMobile] = useState(true)
const [kpi, setKpi] = useState([])
const [chosenDate, setChosenDate] = useState('NONE')
const [chosenFourKubeBalance, setChosenFourKubeBalance] = useState('1000')
const [chosenVOOBalance, setChosenVOOBalance] = useState('1000')
const [stockList, setStockList] = useState([])
const [buyingPriceList, setBuyingPriceList] = useState([])
const [sellingPriceList, setSellingPriceList] = useState([])
const [percentChangeList, setPercentChangeList] = useState([])
const [averagePercChange, setAveragePercChange] = useState('0')
//for the performance table
const [dateList, setDateList] = useState([])
const [fourKubeBal_list, setFourKubeBal_list] = useState([])
const [averagePercentReturn_list, setAveragePercentReturn_list] = useState([])
const [cumulativePercentReturn_list, setCumulativePercentReturn_list] = useState([])
const [voo_list, setVoo_list] = useState([])
const [voo_Challenge_list, setVoo_Challenge_list] = useState([])
const [ticker_list, setTicker_list] = useState([])
var requestOptions = {
method: 'POST',
headers: { 'Content-Type': 'application/json' },
};
useEffect(() => {
if (isMobile === false) {
setIsItMobile(false)
}
requestOptions["body"] = JSON.stringify({})
fetch("https://4ih0epznf8.execute-api.us-east-2.amazonaws.com/".concat('fourkubeStage'))
.then(response => response.json())
.then(data => {
setKpi(data.dataList)
setDateList(data.dataList[0].x)
setFourKubeBal_list(data.dataList[0].yFOURKUBE)
setAveragePercentReturn_list(data.dataList[0].averagePercentReturn_list)
setCumulativePercentReturn_list(data.dataList[0].cumulativePercentReturn_list)
setVoo_list(data.dataList[0].voo_list)
setVoo_Challenge_list(data.dataList[0].vooChallenge_list)
setTicker_list(data.dataList[0].text)
})
}, []);
function displayNotesForGraph(e) {
let theDate = e.points[0].x
setChosenDate(theDate)
let fourKubeBal = e.points[0].data.yFOURKUBE[e.points[0].pointIndex].toFixed(2)
setChosenFourKubeBalance(fourKubeBal)
let VOOBal = e.points[0].data.yVOO[e.points[0].pointIndex].toFixed(2)
setChosenVOOBalance(VOOBal)
//call buy and sell API
var requestOptions = {
method: 'POST',
headers: { 'Content-Type': 'application/json' },
};
fetch("https://morning-brushlands-83469.herokuapp.com/https://bmcwjklg92.execute-api.us-east-2.amazonaws.com/test?theDate=".concat(theDate), requestOptions)
.then(response => response.json())
.then(data => {
let theStockList = JSON.parse(data.body).stockList
setStockList(theStockList)
if (theStockList[0] !== 'NONE') {
setBuyingPriceList(JSON.parse(data.body).buyingPriceList)
setSellingPriceList(JSON.parse(data.body).sellingPriceList)
setPercentChangeList(JSON.parse(data.body).percChangeList)
setAveragePercChange(JSON.parse(data.body).averagePercChange.toFixed(2))
}
})
}
const [videoModalActive, setVideomodalactive] = useState(false);
const openModal = (e) => {
e.preventDefault();
setVideomodalactive(true);
}
const closeModal = (e) => {
e.preventDefault();
setVideomodalactive(false);
}
const outerClasses = classNames(
'hero section center-content',
topOuterDivider && 'has-top-divider',
bottomOuterDivider && 'has-bottom-divider',
hasBgColor && 'has-bg-color',
invertColor && 'invert-color',
className
);
const innerClasses = classNames(
'hero-inner section-inner',
topDivider && 'has-top-divider',
bottomDivider && 'has-bottom-divider'
);
return (
<>
<section
   {...props}
   className={outerClasses}
   >
   <div className="container-sm">
      <div className={innerClasses}>
         <div className="hero-content">
            <h3>
               Make <span className="text-color-primary">Risk</span> Based Decisions
            </h3>
            Utilizing cutting-edge AI backed by statistical models, the FourKube Risk Metric gives clear
            insight as to when safe times to buy are. Watch the video below to learn how to use this tool.
            <br></br><br></br>
            <div>
            <a
              data-video="https://www.youtube.com/embed/T0BPdWiwUp4?si=kDlr9JNfw5YKqO6j"
              href="#0"
              aria-controls="video-modal"
              onClick={openModal}
            >
              <Image
                className="has-shadow"
                src={require('./../../assets/images/risk_vid.png')}
                alt="Hero"
                width={672}
                height={378} />
            </a>
          </div>
          <Modal
            id="video-modal"
            show={videoModalActive}
            handleClose={closeModal}
            video="https://www.youtube.com/embed/T0BPdWiwUp4?si=kDlr9JNfw5YKqO6j"
            videoTag="iframe" />

            <br></br><br></br><br></br>
            <h3 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
               All <span className="text-color-primary">Premium</span> Risk Metric Charts
            </h3>
            <ImageGrid />
         </div>
      </div>
   </div>
</section>
</>
);
}
Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;
export default Hero;
