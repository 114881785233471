import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import PasswordProtected from './PasswordProtected';
import SubscribersRiskMetric from './SubscribersRiskMetric';
const propTypes = {
  ...SectionTilesProps.types
}

const ProtectedPage = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
}) => {

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: "FourKube's Mission",
    paragraph: 'FourKube strives to help the average trader leverage big data and artificial intelligence to make profitable trades. Studies show that around  80% or more of individual traders lose money.  Hedge funds are able win because they have access to information that the general public don’t have and are able to leverage this with big data and artificial intelligence to come out profitable. FourKube has consistently proven to be part of the 20% who profit for over a year now. Not only is the FourKube’s algorithm profitable, but it has been greatly outperforming market indexes including the Dow Jones, SP500, and NASDAQ. After years of using our money in the FourKube’s AI algorithm, we have released it for everyone to see its trades and results. '
  };

  return (
    <PasswordProtected>
      {/* <Risk_section className="illustration-section-01" /> */}
      <SubscribersRiskMetric />
  </PasswordProtected>
  );
}

ProtectedPage.propTypes = propTypes;

export default ProtectedPage;
