import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import { Link } from 'react-router-dom';

import Plot from 'react-plotly.js';
// import { Table } from 'react-bootstrap';
// import Tabs from 'react-bootstrap/Tabs';
// import Tab from 'react-bootstrap/Tab';
// import 'bootstrap/dist/css/bootstrap.min.css';
import MetaTags from 'react-meta-tags';
import {isMobile} from 'react-device-detect';
import { Table } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const [isItMobile, setIsItMobile] = useState(true)
  const [kpi, setKpi] = useState([])
  const [chosenDate, setChosenDate] = useState('NONE')
  const [chosenFourKubeBalance, setChosenFourKubeBalance] = useState('1000')
  const [chosenVOOBalance, setChosenVOOBalance] = useState('1000')
  const [stockList, setStockList] = useState([])
  const [buyingPriceList, setBuyingPriceList] = useState([])
  const [sellingPriceList, setSellingPriceList] = useState([])
  const [percentChangeList, setPercentChangeList] = useState([])
  const [averagePercChange, setAveragePercChange] = useState('0')

  //for the performance table
  const [dateList, setDateList] = useState([])
  const [fourKubeBal_list, setFourKubeBal_list] = useState([])
  const [averagePercentReturn_list, setAveragePercentReturn_list] = useState([])
  const [cumulativePercentReturn_list, setCumulativePercentReturn_list] = useState([])
  const [voo_list, setVoo_list] = useState([])
  const [voo_Challenge_list, setVoo_Challenge_list] = useState([])
  const [ticker_list, setTicker_list] = useState([])

  var requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  };
  useEffect(() => {
    if (isMobile === false) {
      setIsItMobile(false)
    }
    requestOptions["body"] = JSON.stringify({})
    fetch("https://4ih0epznf8.execute-api.us-east-2.amazonaws.com/".concat('fourkubeStage'))
    .then(response => response.json())
    .then(data => {
      setKpi(data.dataList)
      setDateList(data.dataList[0].x)
      setFourKubeBal_list(data.dataList[0].yFOURKUBE)
      setAveragePercentReturn_list(data.dataList[0].averagePercentReturn_list)
      setCumulativePercentReturn_list(data.dataList[0].cumulativePercentReturn_list)
      setVoo_list(data.dataList[0].voo_list)
      setVoo_Challenge_list(data.dataList[0].vooChallenge_list)
      setTicker_list(data.dataList[0].text)
    })
  }, []);

  function displayNotesForGraph(e) {
    let theDate = e.points[0].x
    setChosenDate(theDate)
    let fourKubeBal = e.points[0].data.yFOURKUBE[e.points[0].pointIndex].toFixed(2)
    setChosenFourKubeBalance(fourKubeBal)
    let VOOBal = e.points[0].data.yVOO[e.points[0].pointIndex].toFixed(2)
    setChosenVOOBalance(VOOBal)


    //call buy and sell API
    var requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    };
    fetch("https://morning-brushlands-83469.herokuapp.com/https://bmcwjklg92.execute-api.us-east-2.amazonaws.com/test?theDate=".concat(theDate), requestOptions)
    .then(response => response.json())
    .then(data => {
      let theStockList = JSON.parse(data.body).stockList
      setStockList(theStockList)
      if (theStockList[0] !== 'NONE') {
        setBuyingPriceList(JSON.parse(data.body).buyingPriceList)
        setSellingPriceList(JSON.parse(data.body).sellingPriceList)
        setPercentChangeList(JSON.parse(data.body).percChangeList)
        setAveragePercChange(JSON.parse(data.body).averagePercChange.toFixed(2))
      }
    })

}

  const [videoModalActive, setVideomodalactive] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setVideomodalactive(true);
  }

  const closeModal = (e) => {
    e.preventDefault();
    setVideomodalactive(false);
  }

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <>
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
              Performance <span className="text-color-primary">Graph</span>
            </h1>
            <div className="container-xs">
              <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
              This page is dedicated to the honest and transparent performance of the FourKube algorithm and its subscribers. <b>This page is live and updated automatically daily.</b>
              <br></br>Full trading history can be found <Link to="Performance_Table"><u>here</u></Link> or on our Instagram.
              <br></br><br></br>

                </p><br></br>
                <div className="reveal-from-bottom" data-reveal-delay="600">
                <ButtonGroup>
                  <Link to="Subscribe">
                  <Button tag="a" color="primary" >
                    Subscribe
                    </Button>
                    </Link>
                </ButtonGroup>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <center><i>(Click a point on the graph line to see trade details for that day. Graph is optomized for desktop, not mobile. Expect delays for first click response.)</i></center>

                    <Plot
                    data = {kpi}
                    graphDiv="graph"
                    onClick = {(e) => displayNotesForGraph(e)}
                    style={{ width: '100%' }}
                    layout={{
                      
                      autosize:true,
                      xaxis:{color:'white'},
                      yaxis:{title:'Balance in $US', color: 'white'},
                      paper_bgcolor: '#282c34',
                      plot_bgcolor: '#282c34',
                      title:{text: "FourKube vs Vanguard S&P 500 ETF <br><sup>($1000 Starting Balance on 7/30/20)</sup>", font:{color:'white'}},
                      legend: {font:{color: 'white'}, x: 0, y: 1.075}
                    }}
                    config={{ "displaylogo": false,modeBarButtonsToRemove: ['Zoom','Pan'] }}
                  /> <br></br>








                                {
                  (chosenDate==='NONE') ? null :
                      <>




                      <div style={{ padding: 20}}>
                      <p>
                        <u>Date chosen:</u> <b>{chosenDate}</b><br></br>
                      FourKube Balance: <b>${chosenFourKubeBalance}</b> <br></br>
                      Vanguard S&amp;P 500 Balance: <b>${chosenVOOBalance}</b></p>
                      </div>

                      {(stockList[0]==='NONE') ? <div style={{ padding: 20}}><p><b>(FourKube sold no stocks on {chosenDate})</b></p></div> :<>
          <div style={{ padding: 20}}>
            <Table size="xl" striped bordered hover variant="dark" >
              <thead>
                <tr>
                  <th>Stock List</th>
                  <th>Buying Price (24 hrs earlier)</th>
                  <th>Selling Price on {chosenDate}</th>
                  <th>Percent Change</th>
                </tr>
              </thead>
              <tbody>
                {stockList.map((stock, i) => (
                  <>
                  <tr>
                      <td>{stock}</td>
                      <td>${buyingPriceList[i]}</td>
                      <td>${sellingPriceList[i]}</td>
                      <td>{percentChangeList[i]}%</td>
                    </tr>
                  </>
                ))}
              </tbody>
        </Table>


          <div>Average Percent Change on {chosenDate}: <b>{averagePercChange}%</b></div>
          <small>(FourKube buys stocks 24 hours before they are sold. Both buying and selling occur at ~11am CST)</small><br></br>
          </div>
        </>}
      </>}
      <br></br>
                  </>
  );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
