import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import {isMobile} from 'react-device-detect';
import { Table } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const [isItMobile, setIsItMobile] = useState(true)
  const [kpi, setKpi] = useState([])
  const [chosenDate, setChosenDate] = useState('NONE')
  const [chosenFourKubeBalance, setChosenFourKubeBalance] = useState('1000')
  const [chosenVOOBalance, setChosenVOOBalance] = useState('1000')
  const [stockList, setStockList] = useState([])
  const [buyingPriceList, setBuyingPriceList] = useState([])
  const [sellingPriceList, setSellingPriceList] = useState([])
  const [percentChangeList, setPercentChangeList] = useState([])
  const [averagePercChange, setAveragePercChange] = useState('0')

  //for the performance table
  const [dateList, setDateList] = useState([])
  const [fourKubeBal_list, setFourKubeBal_list] = useState([])
  const [averagePercentReturn_list, setAveragePercentReturn_list] = useState([])
  const [cumulativePercentReturn_list, setCumulativePercentReturn_list] = useState([])
  const [voo_list, setVoo_list] = useState([])
  const [voo_Challenge_list, setVoo_Challenge_list] = useState([])
  const [ticker_list, setTicker_list] = useState([])

  var requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  };
  useEffect(() => {
    if (isMobile === false) {
      setIsItMobile(false)
    }
    requestOptions["body"] = JSON.stringify({})
    fetch("https://4ih0epznf8.execute-api.us-east-2.amazonaws.com/".concat('fourkubeStage'))
    .then(response => response.json())
    .then(data => {
      setKpi(data.dataList)
      setDateList(data.dataList[0].x)
      setFourKubeBal_list(data.dataList[0].yFOURKUBE)
      setAveragePercentReturn_list(data.dataList[0].averagePercentReturn_list)
      setCumulativePercentReturn_list(data.dataList[0].cumulativePercentReturn_list)
      setVoo_list(data.dataList[0].voo_list)
      setVoo_Challenge_list(data.dataList[0].vooChallenge_list)
      setTicker_list(data.dataList[0].text)
    })
  }, []);

  function exportCSV () {
    var requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    };

    fetch("https://morning-brushlands-83469.herokuapp.com/https://bmcwjklg92.execute-api.us-east-2.amazonaws.com/test?theDate=".concat('table'), requestOptions)
    .then(response => response.json())
    .then(data => {
      let url = JSON.parse(data.body)[0].url
      window.open(url)
    })
  }

  const [videoModalActive, setVideomodalactive] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setVideomodalactive(true);
  }

  const closeModal = (e) => {
    e.preventDefault();
    setVideomodalactive(false);
  }

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <>
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
              Performance <span className="text-color-primary">Table</span>
            </h1>
            <div className="container-xs">
              <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
              This page is dedicated to the honest and transparent performance of the FourKube algorithm and its subscribers.<b>This page is live and updated automatically daily.</b>
                </p><br></br>
                <div className="reveal-from-bottom" data-reveal-delay="600">
                <ButtonGroup>
                  <Link to="Subscribe">
                  <Button tag="a" color="primary" >
                    Subscribe
                    </Button>
                    </Link>
                </ButtonGroup>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    {(isItMobile===false) ? <>
    <div style={{float: 'left', padding: 40}}>
          <Button size="lg" onClick={() => exportCSV()}>Export Performance Table to CSV</Button>
        </div> </> : null }


        <Table size="sm" responsive="xl" striped bordered hover variant="dark" style={{ width: '100%' }}>
          <thead>
            <tr>
              <th>Date</th>
              <th>FourKube Balance</th>
              <th>S&P 500 $1000 Challenge</th>
              <th>FourKube Stocks sold List</th>
              <th>Average Percent Return</th>
              <th>Cumulative Percent Return</th>
              <th>VOO (Vanguard S&P 500 ETF)</th>


            </tr>
          </thead>
          <tbody>
            {dateList.map((date, i) => (
              <>
                <tr>
                  <td>{date}</td>
                  <td>${fourKubeBal_list[i]}</td>
                  <td>${voo_Challenge_list[i]}</td>
                  <td>{ticker_list[i]}</td>
                  <td>{averagePercentReturn_list[i]}%</td>
                  <td>{cumulativePercentReturn_list[i]}%</td>
                  <td>${voo_list[i]}</td>
                </tr>
              </>
            ))}
          </tbody>
        </Table>


                  </>
  );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
