import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import Image from '../elements/Image';
import Modal from '../elements/Modal';
import { Link } from 'react-router-dom';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const [videoModalActive, setVideomodalactive] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setVideomodalactive(true);
  }

  const closeModal = (e) => {
    e.preventDefault();
    setVideomodalactive(false);
  }

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
          <center>
            <h5 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
            </h5>
            </center>
            <div className="container-xs">
              <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
              <h3>Make <span className="text-color-primary">AI-Powered</span> Investments</h3>
                  Combining the power of big data, machine learning, and artificial intelligence, FourKube's software web-scrapes megabytes of data every day and uses it to detect profitable trades. 
                  Studies show that&nbsp;
              <a className="App-link"
            href="https://www.curiousgnu.com/day-trading"
            target="_blank"
            rel="noopener noreferrer">
            <u>80% or more</u></a>
            &nbsp;of individual traders lose money.
              FourKube empowers the everyday retail trader to be a part of the 20% that profit. All trading history data is shown and updated daily&nbsp;
              <Link to="Algorithm"><u>here</u></Link>.

        <h3><span className="text-color-primary">Outperform</span> the Markets with AI</h3>
        FourKube's AI algorithm continues to outperform market indexes including the Dow Jones, S&P500, and NASDAQ. After years of testing out the algorithm with our own money, we have released it for everyone to see its trades and results.
        <br></br><br></br>See its performance and full trading history <Link to="Algorithm"><u>here</u></Link>. <br></br><br></br>

        <h3>Join <span className="text-color-primary">AI-Driven Trading Strategies</span></h3>
        Enhance your trading strategies with FourKube's AI technology. Our AI-driven predictions help you make informed decisions and maximize your trading profits. 
        <br></br><br></br>

                        </p>


                        <h3 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
                  We Trade <span className="text-color-primary">Alongside</span> You
               </h3>
               We as founders trade alongside you. 
               Every time the algorithm generates a prediction, FourKube will post a time-stamped screen recording of the founders investing ~$10,000 into the suggested trade on our &nbsp;
               <a href="https://www.instagram.com/fourkube"
                  target="_blank"
                  rel="noopener noreferrer"><u>Instagram</u></a> story — ensuring transparency and further conveying our confidence in the algorithm.
               <br></br><br></br><br></br>


              <div className="reveal-from-bottom" data-reveal-delay="50">
                <ButtonGroup>
                  <Link to="Subscribe">
                  <Button tag="a" color="primary" >
                    Subscribe Here
                    </Button>
                    </Link>&nbsp;&nbsp;&nbsp;
                    <Link to="Algorithm">
                  <Button tag="a" color="dark" to="Algorithm">
                    Visualize our Past Performance since 2020
                    </Button>
                    </Link>
                </ButtonGroup>
              </div>
            </div>
          </div>
          <br></br><br></br>
          <h3> Introducing <span className="text-color-primary">FourKube</span></h3>
          <div className="hero-figure reveal-from-bottom illustration-element-01" data-reveal-value="20px" data-reveal-delay="800">
            <a
              data-video="https://www.youtube.com/embed/W4NLAjXEXcA"
              href="#0"
              aria-controls="video-modal"
              onClick={openModal}
            >
              <Image
                className="has-shadow"
                src={require('./../../assets/images/video-placeholder.jpg')}
                alt="Hero"
                width={896}
                height={504} />
            </a>
          </div>
          <Modal
            id="video-modal"
            show={videoModalActive}
            handleClose={closeModal}
            video="https://www.youtube.com/embed/W4NLAjXEXcA"
            videoTag="iframe" />
        </div>
      </div>
    </section>
  );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
