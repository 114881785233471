import React, { useState } from 'react';
import Modal from './Modal';

const ImageGrid = () => {
  const [selectedImg, setSelectedImg] = useState(null);

  const images = [
    { src: "https://risk-metric-data.s3.us-east-2.amazonaws.com/SP500_market_summary", label: "S&P500" },
    { src: "https://risk-metric-data.s3.us-east-2.amazonaws.com/NASDAQ_market_summary", label: "NASDAQ" },
    { src: "https://risk-metric-data.s3.us-east-2.amazonaws.com/AAPL_market_summary", label: "AAPL" },
    { src: "https://risk-metric-data.s3.us-east-2.amazonaws.com/BTC_Risk_Log_Scale", label: "BTC" },
    { src: "https://risk-metric-data.s3.us-east-2.amazonaws.com/ETH_Risk_Log_Scale", label: "ETH " },
    { src: "https://risk-metric-data.s3.us-east-2.amazonaws.com/TSLA_log_market_summary", label: "TSLA" },
    // ... add other image objects here
  ];

  const gridStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '10px',
  };

  const itemStyle = {
    cursor: 'pointer',
    position: 'relative',
    display: 'flex', // Ensures the contents are flex items
    justifyContent: 'center', // Centers the contents horizontally
    alignItems: 'center', // Centers the contents vertically
    overflow: 'hidden', // Prevents content from spilling outside
  };

  const imgStyle = {
    width: '100%',
    height: 'auto',
    display: 'block',
  };

  const labelStyle = {
    position: 'absolute',
    top: '35%',
    left: '50%', // Move to 50% of the parent's width
    transform: 'translateX(-50%)', // Shift left by 50% of its own width
    color: 'white',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    padding: '5px',
    whiteSpace: 'nowrap', // Keeps the text on a single line
    fontWeight: 'bold', // This makes the text bold
  };

  return (
    <div style={gridStyle}>
      {images.map((image, index) => (
        <div key={index} style={itemStyle} onClick={() => setSelectedImg(image.src)}>
          <img src={image.src} alt={image.label} style={imgStyle} />
          <p style={labelStyle}>{image.label}</p>
        </div>
      ))}
        {selectedImg && <Modal src={selectedImg} onClose={() => setSelectedImg(null)} />}
    </div>
  );
};

export default ImageGrid;

// Modal component here...
