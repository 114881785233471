import React, { useEffect } from 'react';
// import sections
import { useLocation } from 'react-router-dom';

import Hero from '../components/sections/Hero';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import UnsubscribeSection from '../components/sections/UnsubscribeSection';
import Testimonial from '../components/sections/Testimonial';
import Cta from '../components/sections/Cta';
import Subscribe_section from '../components/sections/Subscribe_section';
import ReactGA from 'react-ga';

ReactGA.initialize(process.env.REACT_APP_GA_CODE);

const trackPage = page => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
};

const Subscribe = () => {

  const location = useLocation();

  useEffect(() => {
    // Check if the location.hash matches the section you want to scroll to
    if (location.hash === '#unsubscribe') {
      // Find the element and scroll to it
      const section = document.getElementById('unsubscribe');
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  return (
    <>
      <Subscribe_section className="illustration-section-01" />
      <FeaturesTiles />
      {/* <FeaturesSplit invertMobile topDivider imageFill className="illustration-section-02" /> */}
      <Testimonial topDivider />
      <UnsubscribeSection id="unsubscribe" />
      <Cta split />
    </>
  );
}

export default Subscribe;
