import React from 'react';
import ReactGA from 'react-ga';
import ProtectedPage from '../components/sections/ProtectedPage';

ReactGA.initialize(process.env.REACT_APP_GA_CODE);

const trackPage = page => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
};

const Risk = () => {

  return (
    <>
      <ProtectedPage />
    </>
  );
}

export default Risk;
